import {MDCDialog} from '@material/dialog';
import {MDCSnackbar} from '@material/snackbar';
import {MDCTopAppBar} from "@material/top-app-bar";
import {MDCDrawer} from "@material/drawer";
import {MDCSwitch} from "@material/switch";
import {MDCSelect} from "@material/select";

if(document.querySelector('.mdc-drawer')){

    const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));
    const topAppBar = MDCTopAppBar.attachTo(document.getElementById('app-bar'));
        topAppBar.setScrollTarget(document.getElementById('main-content'));
        topAppBar.listen('MDCTopAppBar:nav', () => {
            drawer.open = !drawer.open;
    });

}

if(document.querySelector('#seltoaddr')) {
    document.querySelector('#seltoaddr').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#toadrpop')).open();
    });
}
if(document.querySelector('#selfromaddr')) {
    document.querySelector('#selfromaddr').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#fromadrpop')).open();
    });
}
if(document.querySelector('#addarticle')) {
    document.querySelector('#addarticle').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#createarticlepop')).open();
    });
}
if(document.querySelector('#openstockdetails')) {
    let stockpops = document.querySelectorAll('#openstockdetails');

    for (var i = 0, len = stockpops.length; i < len; i++) {
        stockpops[i].addEventListener('click', function () {
            new MDCDialog(document.querySelector('#stockdetails')).open();
        });
    }
}
if(document.querySelector('#opennotedetails')) {
    document.querySelector('#opennotedetails').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#notedetails')).open();
    });
}
if(document.querySelector('#openinfounits')) {
    document.querySelector('#openinfounits').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#infounits')).open();
    });
}
if(document.querySelector('#openzipcodeselectto')) {
    document.querySelector('#openzipcodeselectto').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#zipcodeselectto')).open();
    });
}
if(document.querySelector('#openzipcodeselectfrom')) {
    document.querySelector('#openzipcodeselectfrom').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#zipcodeselectfrom')).open();
    });
}
if(document.querySelector('#openinfoweight')) {
    document.querySelector('#openinfoweight').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#infoweight')).open();
    });
}
if(document.querySelector('#openinfolm')) {
    document.querySelector('#openinfolm').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#infolm')).open();
    });
}
if(document.querySelector('#openorderdetails')) {
    document.querySelector('#openorderdetails').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#orderdetails')).open();
    });
}
if(document.querySelector('#openhistorydetails')) {
    document.querySelector('#openhistorydetails').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#historydetails')).open();
    });
}
if(document.querySelector('#iconlegendbutton')) {
    document.querySelector('#iconlegendbutton').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#iconlegend')).open();
    });
}
if(document.querySelector('#openhelplogin')) {
    document.querySelector('#openhelplogin').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#helplogin')).open();
    });
}
if(document.querySelector('#openappmodal')) {
    document.querySelector('#openappmodal').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#appmodal')).open();
    });
}
if(document.querySelector('#openpodpop')) {
    document.querySelector('#openpodpop').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#podpop')).open();
    });
}
if(document.querySelector('#opentrackingpop')) {
    document.querySelector('#opentrackingpop').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#trackingpop')).open();
    });
}
if(document.querySelector('#openadrpop')) {
    document.querySelector('#openadrpop').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#adrpop')).open();
    });
}
if(document.querySelector('#openpreplan')) {
    document.querySelector('#openpreplan').addEventListener('click', function () {
        new MDCDialog(document.querySelector('#preplan')).open();
    });
}
if(document.querySelector('#openpassword')) {
    let itempops = document.querySelectorAll('#openpassword');

    for (var i = 0, len = itempops.length; i < len; i++) {
        itempops[i].addEventListener('click', function () {
            new MDCDialog(document.querySelector('#passwordpop')).open();
        });
    }
}
if(document.querySelector('#openeditarticlepop')) {
    let itempops = document.querySelectorAll('#openeditarticlepop');

    for (var i = 0, len = itempops.length; i < len; i++) {
        itempops[i].addEventListener('click', function () {
            new MDCDialog(document.querySelector('#editarticlepop')).open();
        });
    }
}
if(document.querySelector('#openeditssccpop')) {
    let ssccpop = document.querySelectorAll('#openeditssccpop');

    for (var i = 0, len = ssccpop.length; i < len; i++) {
        ssccpop[i].addEventListener('click', function () {
            new MDCDialog(document.querySelector('#editssccpop')).open();
        });
    }
}
if(document.querySelector('#openadreditpop')) {
    let itempops = document.querySelectorAll('#openadreditpop');
    for (var i = 0, len = itempops.length; i < len; i++) {
        itempops[i].addEventListener('click', function () {
            new MDCDialog(document.querySelector('#editadrpop')).open();
        });
    }
}

if(document.querySelector('.mdc-switch')) {
    let switches = document.querySelectorAll('.mdc-switch');
    for (var i = 0, len = switches.length; i < len; i++) {
        new MDCSwitch(switches[i]);
    }
}

var messageEvent = document.createEvent("CustomEvent");
messageEvent.initCustomEvent("messages", false, false, {
    detail: "Test",
    time: 5000
});
var itempopEvent = new CustomEvent("itempop", {detail: "Test"});
var ssccpopEvent = new CustomEvent("ssccpop", {detail: "Test"});
var adrpopEvent = new CustomEvent("adrpop", {detail: "Test"});
var passwordEvent = new CustomEvent("password", {detail: "Test"});
var reinitEvent = new CustomEvent("reinit", {detail: "Test"});
var popupblockerevent = new CustomEvent("popupblocker", {detail: "Test"});

document.querySelector('#body').addEventListener('message', function (e) {
    let snack = new MDCSnackbar(document.querySelector('.mdc-snackbar'));
    snack.labelText = e.detail;
    snack.setTimeoutMs = e.time;
    snack.open();
}, false);

document.querySelector('#body').addEventListener('itempop', function (e) {
    let itempops = document.querySelectorAll('#openeditarticlepop');

    for (var i = 0, len = itempops.length; i < len; i++) {
        itempops[i].addEventListener('click', function () {
            new MDCDialog(document.querySelector('#editarticlepop')).open();
        });
    }
}, false);
document.querySelector('#body').addEventListener('ssccpop', function (e) {
    let ssccpop = document.querySelectorAll('#openeditssccpop');

    for (var i = 0, len = ssccpop.length; i < len; i++) {
        ssccpop[i].addEventListener('click', function () {
            new MDCDialog(document.querySelector('#editssccpop')).open();
        });
    }
}, false);
document.querySelector('#body').addEventListener('adrpop', function (e) {
    let itempops = document.querySelectorAll('#openadreditpop');
    for (var i = 0, len = itempops.length; i < len; i++) {
        itempops[i].addEventListener('click', function () {
            new MDCDialog(document.querySelector('#editadrpop')).open();
        });
    }
}, false);
document.querySelector('#body').addEventListener('password', function (e) {
    new MDCDialog(document.querySelector('#passwordpop')).open();
}, false);
document.querySelector('#body').addEventListener('popupblocker', function (e) {
    new MDCDialog(document.querySelector('#popupblocked')).open();
}, false);
document.querySelector('#body').addEventListener('reinit', function (e) {
    require("material-components-web").autoInit();
}, false);

require("material-components-web").autoInit();
